import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, timer, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IdleTimeoutModalComponent } from '../components/idle-timeout-modal/idle-timeout-modal.component';
import { AuthService } from 'src/app/modules/auth/_services/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { IDLE_TIMEOUT, IDLE_TIMEOUT_WARNING } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService implements OnDestroy {
  private readonly TOTAL_TIMEOUT_MINUTES = IDLE_TIMEOUT;
  private readonly WARNING_BEFORE_MINUTES = IDLE_TIMEOUT_WARNING;

  private idleSubject = new BehaviorSubject<boolean>(false);
  idle$ = this.idleSubject.asObservable();

  private timeoutTimer$: Observable<number>;
  private activityEvents$: Observable<Event>;
  private timerSubscription: Subscription | null = null;
  private warningModalRef: NgbModalRef | null = null;
  private isLoggedIn = false;
  private isHandlingTimeout = false;

  constructor(
    private dialog: NgbModal,
    private authService: AuthService
  ) {
    // Subscribe to auth state to start/stop idle tracking
    this.authService.currentUser$.subscribe(user => {
      const wasLoggedIn = this.isLoggedIn;
      this.isLoggedIn = !!user;

      if (!wasLoggedIn && this.isLoggedIn) {
        // Just logged in
        this.setupIdleTracking();
      } else if (wasLoggedIn && !this.isLoggedIn) {
        // Just logged out
        this.stopIdleTracking();
      }
    });
  }

  private setupIdleTracking() {
    // Stop any existing tracking
    this.stopIdleTracking();

    // Combine multiple events that indicate user activity
    this.activityEvents$ = merge(
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keydown'),
      fromEvent(document, 'click'),
      fromEvent(window, 'scroll')
    );

    // Create a timer that tracks total idle time
    this.timeoutTimer$ = timer(this.TOTAL_TIMEOUT_MINUTES * 60 * 1000).pipe(
      takeUntil(this.activityEvents$)
    );

    // Track the countdown to show warning
    const warningTimer$ = timer((this.TOTAL_TIMEOUT_MINUTES - this.WARNING_BEFORE_MINUTES) * 60 * 1000);

    // Subscribe to timers
    const warningSubscription = warningTimer$.subscribe(() => {
      if (this.isLoggedIn && !this.isHandlingTimeout) {
        this.showWarningModal();
      }
    });

    const timeoutSubscription = this.timeoutTimer$.subscribe(() => {
      if (this.isLoggedIn && !this.isHandlingTimeout) {
        this.handleIdleTimeout();
      }
    });

    // Store subscriptions to clean up later
    this.timerSubscription = new Subscription();
    this.timerSubscription.add(warningSubscription);
    this.timerSubscription.add(timeoutSubscription);
  }

  private showWarningModal() {
    // Close any existing warning modal first
    if (this.warningModalRef) {
      try {
        this.warningModalRef.dismiss();
      } catch { }
      this.warningModalRef = null;
    }

    // Close all open dialogs
    this.dialog.dismissAll();

    // Open new warning modal
    this.warningModalRef = this.dialog.open(IdleTimeoutModalComponent, {
      size: 'md',
      backdrop: 'static',
      centered: true
    });

    // Set remaining time on modal instance
    if (this.warningModalRef.componentInstance) {
      this.warningModalRef.componentInstance.remainingTime = this.WARNING_BEFORE_MINUTES * 60;
    }

    // Handle modal close
    this.warningModalRef.result.then(
      (result) => {
        if (result === 'continue') {
          // Reset the idle tracking
          this.setupIdleTracking();
        } else {
          // User chose to logout
          this.forceLogout();
        }
      },
      () => {
        // Modal dismissed, force logout
        this.forceLogout();
      }
    );
  }

  private handleIdleTimeout() {
    if (!this.isHandlingTimeout) {
      this.isHandlingTimeout = true;
      this.forceLogout();
    }
  }

  private forceLogout() {
    try {
      // Close any open modals
      this.dialog.dismissAll();

      // Perform logout
      this.authService.logout();
    } finally {
      // Ensure tracking is stopped
      this.stopIdleTracking();
      this.isHandlingTimeout = false;
    }
  }

  private stopIdleTracking() {
    // Unsubscribe from all timers
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }

    // Close any open warning modal
    if (this.warningModalRef) {
      try {
        this.warningModalRef.dismiss();
      } catch { }
      this.warningModalRef = null;
    }
  }

  // Implement OnDestroy to ensure cleanup
  ngOnDestroy() {
    this.stopIdleTracking();
  }

  // Method to manually reset idle timer
  resetIdleTimer() {
    if (this.isLoggedIn) {
      this.setupIdleTracking();
    }
  }
}
