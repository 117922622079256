import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { interval, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { TranslateModule } from "@ngx-translate/core";
import { IDLE_TIMEOUT_WARNING } from "../../app.constants";
import { addMinutes, differenceInSeconds } from "date-fns";

@Component({
  selector: "app-idle-timeout-modal",
  template: `
    <div class="d-flex justify-content-end mx-5 mt-5">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="continue()"
      ></button>
    </div>
    <div class="modal-body">
      <div class="text-center pb-4">
        <span class="fw-bolder fs-4">
          {{ "idleTimeout.title" | translate }}
        </span>
      </div>
      <div class="text-center mt-2">
        <p>{{ "idleTimeout.message" | translate }}</p>
      </div>
      <div class="text-center my-4 fs-2">
        {{ formattedTime }}
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="logout()"
      >
        {{ "idleTimeout.logout" | translate }}
      </button>
      <button type="button" class="btn btn-primary" (click)="continue()">
        {{ "idleTimeout.continue" | translate }}
      </button>
    </div>
  `,
  styles: [
    `
      .modal-body {
        text-align: center;
        font-size: 1.1rem;
        padding: 1rem;
      }
      .modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 1rem;
      }
      .btn-close {
        color: white;
        opacity: 0.8;
      }
    `,
  ],
  standalone: true,
  imports: [TranslateModule],
})
export class IdleTimeoutModalComponent implements OnInit, OnDestroy {
  remainingTime: Date;
  formattedTime: string = "00:00";

  private countdownSubscription: Subscription;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.remainingTime = addMinutes(new Date(), IDLE_TIMEOUT_WARNING);
    this.startCountdown();
  }

  private startCountdown() {
    this.countdownSubscription = interval(1000)
      .subscribe(() => {
        const timeLeft = differenceInSeconds(this.remainingTime, new Date());
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;

        // Pad single digit seconds with a leading zero
        const formattedSeconds = seconds.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");

        this.formattedTime = `${formattedMinutes}:${formattedSeconds}`;

        if (timeLeft <= 0) {
          this.logout();
        }
      });
  }

  continue() {
    // Stop the countdown before closing
    this.stopCountdown();
    this.activeModal.close("continue");
  }

  logout() {
    // Stop the countdown before closing
    this.stopCountdown();
    this.activeModal.close("logout");
  }

  dismiss() {
    // Stop the countdown before closing
    this.stopCountdown();
    this.activeModal.dismiss();
  }

  private stopCountdown() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.stopCountdown();
  }
}
