import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

export class ToastStatus {
  public static SUCCESS = "success";
  public static DANGER = "danger";
  public static WARNING = "warning";
  public static PRIMARY = "primary";
  public static DEFAULT = "default";
  public static INFO = "info";
}

/**
 * Service to show tostr message to the user
 */
@Injectable({
  providedIn: "root",
})
export class ToasterService {
  /**
   * Constructor
   * @param toastrService
   */
  constructor(private toastrService: ToastrService) {}

  /**
   * To show the sucess toast to the user
   * @param message
   * @param title
   */
  showSuccessToast(message, title = "Success") {
    this.toastrService.success(title, message);
  }

  /**
   * To show the error toast to the user
   * @param message
   * @param title
   * @param timeOut
   */
  showErrorToast(message, title = "Error", timeOut = 5000) {    
    this.toastrService.warning(title, message, {
      timeOut,
      closeButton: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      toastClass: 'ngx-toastr custom-error-toast'
    });
  }
  /**
   * To sho the error toast to the user
   * @param message
   * @param title
   */
  showInfoToast(message, title = "Information") {
    this.toastrService.info(title, message);
  }

  /**
   * To sho the error toast to the user
   * @param message
   * @param title
   */
  showWarningToast(message, title = "Error",  timeOut = 5000) {
    this.toastrService.warning(title, message, { timeOut });
  }
}
